import { colors } from "@/styles/global.styles";
import twitterData from "@emoji-mart/data/sets/15/twitter.json";
import Picker from "@emoji-mart/react";
import { css } from "@emotion/react";
import Color from "color";
import React, { forwardRef, memo, useEffect, useMemo, useRef } from "react";
import { mergeRefs } from "react-merge-refs";

type EmojiPickerProps = {
  handleEmojiClick: (emoji: string) => void;
  visible: boolean;
  uiMode?: "MESSAGE_REACTION" | "SEND_MESSAGE";
};

const EmojiPicker = memo(
  forwardRef<HTMLDivElement, EmojiPickerProps>((props, ref) => {
    const emojiPickerRef = useRef<HTMLDivElement>(null!);
    useAddEmojiPickerStyles(
      emojiPickerRef,
      `.search input[type="search"]:focus { box-shadow: none; }
      .scroll::-webkit-scrollbar {
        width: 14px;
      } 
      .scroll::-webkit-scrollbar-thumb {
        background-color: rgb(140, 143, 146, var(--scrollbar-alpha)) !important;
        border-radius: 14px;
      }
      .scroll::-webkit-scrollbar-track {
          background-color: rgb(46, 50, 55, var(--scrollbar-alpha));
          border-radius: 14px;
      }`
    );

    const pickerProperties = {
      emojiButtonSize: 46,
      emojiSize: 32,
      autoFocus: true,
      dynamicWidth: true,
    };

    let wrapperCssCustom = css();

    if (props.uiMode === "MESSAGE_REACTION") {
      Object.assign(pickerProperties, {
        emojiButtonSize: 35,
        emojiSize: 20,
        searchPosition: "none",
        perLine: 7,
        autoFocus: false,
        dynamicWidth: false,
      });

      wrapperCssCustom = css(
        {
          width: "auto !important",
        },
        `em-emoji-picker {
      width: auto !important;
      max-width: auto !important;
      height: 20vh !important;
      min-height: auto !important;
      max-height: auto !important;
    }`
      );
    }

    const picker = useMemo(
      () => (
        <Picker
          onEmojiSelect={(emoji: { native: string }) => {
            props.handleEmojiClick(emoji.native);
          }}
          data={twitterData}
          set="twitter"
          navPosition="bottom"
          previewPosition="none"
          reactionsDefaultOpen={true}
          {...pickerProperties}
        />
      ),
      []
    );

    return (
      <div
        ref={mergeRefs([emojiPickerRef, ref])}
        id="EmojiPicker"
        css={[wrapperCss, wrapperCssCustom]}
        style={{ display: props.visible ? "block" : "none" }}
      >
        {picker}
      </div>
    );
  })
);

function useAddEmojiPickerStyles(
  emojiPickerRef: React.MutableRefObject<HTMLElement>,
  styles: string
) {
  useEffect(() => {
    const shadowRoot =
      emojiPickerRef.current.querySelector("em-emoji-picker")?.shadowRoot;
    if (!shadowRoot) {
      console.warn("Emoji picker shadow root not found");
      return;
    }

    const sheet = new CSSStyleSheet();
    sheet.replaceSync(styles);
    shadowRoot.adoptedStyleSheets = [sheet];
  }, [emojiPickerRef, styles]);
}

const wrapperCss = css(
  {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: "0",
    bottom: "100%",
    zIndex: 100,
    paddingBottom: "0.5em",
    transform: "translateX(45px)",
    width: "100%",
    // sizing for smaller screens
    "@media (max-width: 1280px)": {
      transform: "translateX(0)",
      "& em-emoji-picker": {
        width: "100% !important",
        maxWidth: "100% !important",
      },
    },
    // weird wrapper div around em-emoji-picker
    "& > div": {
      width: "100%",
      height: "100%",
    },
  },
  `em-emoji-picker {
    --border-radius: 16px;
    --category-icon-size: 24px;
    --color-border-over: rgba(0, 0, 0, 0.1);
    --color-border: rgba(0, 0, 0, 0.15);
    --font-size: 18px;
    --rgb-accent: ${new Color(colors.primaryAccentColor)
      .rgb()
      .array()
      .join(", ")};
    --rgb-background: ${new Color(colors.secondaryBackground)
      .rgb()
      .array()
      .join(", ")};
    --rgb-color: ${new Color(colors.secondaryTextColor)
      .rgb()
      .array()
      .join(", ")};
    --rgb-input: ${new Color(colors.secondaryBackgroundLighter)
      .rgb()
      .array()
      .join(", ")};
    --shadow: 0 4px 8px 0px rgb(0,0,0,0.25);

    width: 30vw;
    max-width: 420px;
    height: 50vh;
    min-height: 400px;
    max-height: 800px;
  }`
);

export default EmojiPicker;
