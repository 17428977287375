import { colors } from "@/styles/global.styles";
import { css } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { atom } from "jotai";

export const isVideoWallCallAtom = atom<boolean>(false);
export const usePreviewFrameAtom = atom("");

type VideoWallRequestProps = {
  socketRef: React.MutableRefObject<SocketIOClient.Socket | null>;
  profileComplete: boolean;
  onActionToParticipate: () => void;
  onActionToAccept: (socketRef) => void;
  onActionToDecline: (socketRef) => void;
};

const VideoWallRequest = ({
  socketRef,
  profileComplete,
  onActionToParticipate,
  onActionToAccept,
  onActionToDecline,
}: VideoWallRequestProps) => {
  return (
    <div css={videoWallDivStyle}>
      <div css={videoWallColStyle}>
        <div css={videoWallRowStyle}>
          {profileComplete && (
            <span css={videoWallTextStyle}>
              You've been invited to join the video wall
            </span>
          )}
          <button
            css={closeButtonStyle}
            onClick={() => onActionToDecline(socketRef.current)}
          >
            <CloseIcon css={closeIconStyle} />
          </button>
        </div>

        <div css={videoWallButtonRowStyle}>
          {profileComplete ? (
            <>
              <button
                onClick={() => onActionToDecline(socketRef.current)}
                css={[
                  videoWallButton,
                  { backgroundColor: colors.primaryAccentColor },
                ]}
              >
                Dismiss
              </button>
              <button
                onClick={() => onActionToAccept(socketRef.current)}
                css={[
                  videoWallButton,
                  { backgroundColor: colors.secondaryAccentColor },
                ]}
              >
                Join Video Wall
              </button>
            </>
          ) : (
            <div css={enterDisplayNameStyle} onClick={onActionToParticipate}>
              Enter Display Name to participate
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoWallRequest;

const videoWallButton = css({
  width: "8em",
  height: "3.5em",
  userSelect: "none",
  borderRadius: "10px",
});

const videoWallRowStyle = css({
  display: "flex",
  gap: "0.6em",
  alignItems: "center",
  justifyContent: "center",
});

const videoWallIconStyle = css({
  width: "3em",
  height: "3em",
  borderRadius: "50%",
  backgroundColor: "#222222",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const videoWallTextStyle = css({
  marginRight: "0.6em",
  fontSize: "1.25rem",
  fontWeight: "bold",
});

const videoWallDivStyle = css({
  position: "absolute",
  color: colors.primaryTextColor,
  bottom: "50%",
  transform: "translate(-50%, 50%)",
  display: "flex",
  zIndex: 115,
  padding: "1.25em",
  backgroundColor: "#141414",
  borderRadius: "10px",
  transition: "opacity 0.5s ease",
});

const videoWallColStyle = css({
  display: "flex",
  gap: "1.25em",
  flexDirection: "column",
  width: "100%",
});

const videoWallButtonRowStyle = css({
  display: "flex",
  gap: "0.6rem",
  alignItems: "end",
  justifyContent: "end",
  width: "100%",
});

const enterDisplayNameStyle = css({
  textDecoration: "none",
  border: "none",
  borderRadius: "10px",
  padding: "2vh 1vw",
  color: colors.primaryTextColor,
  backgroundColor: colors.tertiaryBackground,
  fontSize: "1em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "13em",
  cursor: "pointer",
});

const closeButtonStyle = css({
  display: "flex",
  alignItems: "top",
  justifyContent: "top",
});

const closeIconStyle = css({ width: "1.5em", height: "1.5em" });
