import { colors } from "@/styles/global.styles";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";
import DialpadIcon from "@mui/icons-material/Dialpad";
import GestureIcon from "@mui/icons-material/Gesture";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import MicNoneIcon from "@mui/icons-material/MicNone";
import MicOffIcon from "@mui/icons-material/MicOff";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { CallButtonsProps } from "./CallOverlays";

import { useToast } from "@/utils/helpers/toastManager";
import WebGwContact from "@/utils/helpers/WebGwContact";
import {
  ControlBar,
  ControlButtonLarge,
  ControlButtonSmall,
} from "./Overlay.style";

export type CallOverlayProps = CallButtonsProps & {
  open: boolean;
  isFullScreen: boolean;
  contact: WebGwContact | undefined;
  time: Date;
  toggleFullScreen: () => void;
  isPaused: boolean;
  isVideoWallCall?: boolean;
};
export function ButtonBar({
  callActive,
  callMuted,
  muteCall,
  endCall,
  showNotImplemented,
  isFullScreen,
  isVideoWallCall,
}: CallButtonsProps) {
  const { showToast } = useToast();
  const notImplemented = false;

  const ControlButton = isFullScreen ? ControlButtonLarge : ControlButtonSmall;

  return (
    <div
      css={{
        display: "flex",
        gap: "2em",
        justifyContent: "center",
        filter: `drop-shadow(0 0 4px rgb(0,0,0,0.25))`,
      }}
    >
      <ControlBar css={!isFullScreen && { borderRadius: "6px" }}>
        <button
          css={[
            ControlButton,
            {
              backgroundColor: colors.primaryAccentColor,
              ":hover": { backgroundColor: colors.primaryAccentColor },
            },
          ]}
          onClick={endCall}
        >
          <CallEndOutlinedIcon />
        </button>
        <button
          css={ControlButton}
          onClick={
            isVideoWallCall
              ? () => showToast("Audio is controlled by the moderator")
              : muteCall
          }
        >
          {callMuted || isVideoWallCall ? <MicOffIcon /> : <MicNoneIcon />}
        </button>
        {notImplemented && (
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <PauseOutlinedIcon />
          </button>
        )}
        {notImplemented && (
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <VideocamOutlinedIcon
              css={{
                color: colors.primaryAccentColor,
              }}
            />
          </button>
        )}
      </ControlBar>
      {notImplemented && (
        <ControlBar>
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <AddIcCallOutlinedIcon />
          </button>
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <DialpadIcon />
          </button>
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <MessageOutlinedIcon />
          </button>
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <GestureIcon />
          </button>
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <MapOutlinedIcon />
          </button>
        </ControlBar>
      )}
    </div>
  );
}
