const RELOADED_IN_LAST_10_SECONDS_KEY = "reloadedInLast10Seconds";
const LAST_GROUP_ID_KEY = "lastGroupId";
const LAST_EVENT_ID_KEY = "lastEventId";
const EMAIL_KEY = "email";
const SWIPE_TO_EXPLORE_PROMPT_KEY = "swipeToExplorePrompt";
const LAST_EMBEDDED_ODIENCE_PAGE_KEY = "lastEmbeddedOdiencePage";
const TAD_ID_KEY_KEY = "tabId";
const NMS_LOGGED_IN_KEY = "nmsLoggedIn";

export const setReloadedInLast10Seconds = (value: Date) => {
  set(RELOADED_IN_LAST_10_SECONDS_KEY, value.toString());
};

export const getReloadedInLast10Seconds = () => {
  return get(RELOADED_IN_LAST_10_SECONDS_KEY);
};

export const setLastGroupId = (value: string) => {
  set(LAST_GROUP_ID_KEY, value);
};

export const getLastGroupId = () => {
  return get(LAST_GROUP_ID_KEY);
};

export const removeLastGroupId = () => {
  remove(LAST_GROUP_ID_KEY);
};

export const setLastEventId = (value: string) => {
  set(LAST_EVENT_ID_KEY, value);
};

export const getLastEventId = () => {
  return get(LAST_EVENT_ID_KEY);
};

export const removeLastEventId = () => {
  remove(LAST_EVENT_ID_KEY);
};

export const setEmail = (value: string) => {
  set(EMAIL_KEY, value);
};

export const getEmail = () => {
  return get(EMAIL_KEY);
};

export const removeEmail = () => {
  remove(EMAIL_KEY);
};

export const setSwipeToExplorePrompt = (value: string) => {
  set(SWIPE_TO_EXPLORE_PROMPT_KEY, value);
};

export const getSwipeToExplorePrompt = () => {
  return get(SWIPE_TO_EXPLORE_PROMPT_KEY);
};

export const setLastEmbeddedOdiencePage = (value: string) => {
  set(LAST_EMBEDDED_ODIENCE_PAGE_KEY, value);
};

export const getLastEmbeddedOdiencePage = () => {
  return get(LAST_EMBEDDED_ODIENCE_PAGE_KEY);
};

export const setTabId = (value: string) => {
  set(TAD_ID_KEY_KEY, value);
};

export const getTabId = () => {
  return get(TAD_ID_KEY_KEY);
};

export const removeTabId = () => {
  remove(TAD_ID_KEY_KEY);
};

export const setNmsLoggedIn = (value: string) => {
  set(NMS_LOGGED_IN_KEY, value);
};

export const getNmsLoggedIn = () => {
  return get(NMS_LOGGED_IN_KEY);
};

export const removeNmsLoggedIn = () => {
  remove(NMS_LOGGED_IN_KEY);
};

const get = (item: string) => {
  return sessionStorage.getItem(item);
};

const set = (item: string, value: string) => {
  sessionStorage.setItem(item, value);
};

const remove = (item: string) => {
  sessionStorage.removeItem(item);
};
