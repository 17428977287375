import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { colors } from "./styles/global.styles";
import {
  getReloadedInLast10Seconds,
  setReloadedInLast10Seconds,
} from "./utils/helpers/sessionStorage";

export default function RouterErrorHandler() {
  const error = useRouteError() as Error; // as Error assuming only Errors are thrown

  const includesFailedToFetch = error.message.includes(
    "Failed to fetch dynamically imported module"
  );

  useEffect(() => {
    if (!includesFailedToFetch) return;

    const doReload = () => {
      setReloadedInLast10Seconds(new Date());

      window.location.reload();
    };

    const reloadedInLast10SecondsStr = getReloadedInLast10Seconds();
    const reloadedInLast10Seconds =
      reloadedInLast10SecondsStr &&
      new Date(reloadedInLast10SecondsStr) >
        new Date(new Date().getTime() - 10_000);

    if (reloadedInLast10Seconds) {
      console.error(
        "Failed to fetch dynamically imported module. Reloaded in last 10 seconds. Aborting reload."
      );
      setTimeout(doReload, 10_000);
    } else {
      doReload();
    }
  }, [error]);

  if (includesFailedToFetch) {
    return null;
  }

  //TODO READD need to add more conditions before rendering it
  // return (
  //   <div css={styles.root}>
  //     <div css={styles.container}>
  //       <h2 css={styles.heading}>Error</h2>
  //       <p css={styles.error}>{error.stack}</p>
  //     </div>
  //   </div>
  // );
}

const styles = {
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    width: "100%",
    maxWidth: 600,
    margin: "auto",
    padding: 20,
    backgroundColor: colors.primaryBackground,
    borderRadius: 5,
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  heading: {
    color: colors.primaryAccentColor,
    margin: 0,
    fontSize: 24,
    fontWeight: "bold",
  },
  error: {
    color: colors.primaryTextColor,
    fontSize: 16,
    marginTop: 10,
  },
};
