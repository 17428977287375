import { atom } from "jotai";
import type Conversation from "./Conversation";

// Atom used for new message notifications
export const showNewMessageNotificationAtom = atom(false);
export const currentMessageNotificationSenderAtom = atom("");
export const previousMessageNotificationSenderAtom = atom("");
export const keepPreviousMessageNotificationChatOverlayAtom = atom(false);
export const autoOpenCurrentMessageNotificationAtom = atom(0);
export const selectedConversationsAtom = atom<Conversation[]>([]);
export const selectedConversationsCheckboxesAtom = atom<string[]>([]);
export const callTranscriptTextAtom = atom("");
export const callTranscriptRemoteSenderAtom = atom("");
export const disableIncomingNotificationForAddressAtom = atom("");
