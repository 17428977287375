import {
  getImpi,
  getLocalAccessToken,
  getPassword,
  getUsername,
} from "./localstorage";

export enum ProvisioningSteps {
  StepRelogin, // We check if we were already provisioned, if yes, we skip to last step
  StepWelcomeMessage, // We check if remember me, if yes, we skip, if not Simple message for the user
  StepLogin, // We provide our identity and we get an accessToken
  StepWaitForOtp, // We open a connection and wait for the OTP to be provided
  StepRegistered, // Successful registration, navigate to app
  StepRemoveDevice, // We reached the maximum amount of devices possible, we need to clear a spot.
  StepTransferSession, // If we get a 409, this means we are already logged in elsewhere, give the user the option to continue anyway and remove the other active session
  StepHandleDisconnectLoginPage, // If a user gets disconnected due to relogin or 403 issue show them alternate login page
}

export type DeviceNotification = {
  DeviceList: any[];
  MaxDevice: number;
};

export type LoginResultNotification = {
  access_token: string;
  otprequired?: true;
  state: string;
  result: string | any[] | DeviceNotification;
  reason?: string;
  request?: string;
  status_code?: number;
  firstTimeUser?: boolean;
};

export const LOGIN_RESULT_NOTIFICATION_STATUS_CODE = {
  OtpWrong: 3,
  OtpCreationLimitReached: 4,
  OtpAlreadyUsed: 5,
  OtpTooManyTries: 6,
  OtpExpired: 7,
  OtpNotProvided: 100,
  ImdnWrongFormat: 33,
  TimeOut: 408,
};

export type WebGwResponse = {
  notificationList: {
    loginResultNotification: LoginResultNotification;
  };
};

export type WebGwResponseOTP = {
  notificationList: {
    loginResultNotification: LoginResultNotification & {
      otprequired: true;
    };
  };
};

export function isProvisioned() {
  return wasPreprovisioned() && !!getLocalAccessToken();
}

export function wasPreprovisioned() {
  return !!getImpi() && !!getUsername() && !!getPassword();
}
