import { paths } from "@/routerPaths";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function generateRandomString(length: number): string {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

let isMobileCached = false;
export function isMobile() {
  isMobileCached = !!(
    isAndroid() ||
    isIOS() ||
    navigator.userAgent.match(/webOS/i) ||
    (navigator.userAgent.match(/Mac/) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i) ||
    navigator.userAgent.match(/IEMobile/i) ||
    navigator.userAgent.match(/MSIEMobile/i)
  );
  return isMobileCached;
}

let isAndroidCached = false;
export function isAndroid() {
  isAndroidCached = !!navigator.userAgent.match(/Android/i);
  return isAndroidCached;
}

let isIOSCached = false;
export function isIOS() {
  isIOSCached = !!(
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i)
  );
  return isIOSCached;
}

let isMacCached = false;
export function isMac() {
  isMacCached = !!navigator.userAgent.match(/Macintosh/i);
  return isMacCached;
}

export async function fetchWithTimeout(url, opts = {}, timeout = 5000) {
  // Create a signal with timeout
  const signal = AbortSignal.timeout(timeout);

  // Make the fetch request
  const _fetchPromise = fetch(url, {
    ...opts,
    signal,
  });
  try {
    // Await the fetch with a catch in case it's aborted which signals an error
    return await _fetchPromise;
  } catch (e) {
    return undefined;
  }
}

export const contactNameRegexPattern = /^[+#*()0-9]/;

export function delay(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const useOnboardingNavigationAlert = () => {
  const location = useLocation();

  useEffect(() => {
    const handlePopstate = () => {
      if (
        location.pathname === paths.onboarding ||
        location.pathname === paths.provisioning
      ) {
        if (!window.confirm("Are you sure you want to leave Verse?")) {
          window.history.pushState(null, "", location.pathname);
        }
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [location]);
};

export const isValidContact = (contact): boolean => {
  const hasNonEmptyField = (obj): boolean => {
    let nameNotEmpty = false;
    let nonEmptyField = false;

    for (let key in obj) {
      if (
        key !== "contactId" &&
        (key === "phone" ||
          key === "email" ||
          key === "address" ||
          key === "related")
      ) {
        if (Array.isArray(obj[key])) {
          for (let i = 0; i < obj[key].length; i++) {
            for (let prop in obj[key][i]) {
              if (
                obj[key][i].hasOwnProperty(prop) &&
                prop !== "id" &&
                typeof obj[key][i][prop] === "string" &&
                obj[key][i][prop].trim() !== ""
              ) {
                nonEmptyField = true;
                break;
              }
            }
          }
        }
      }
      // Check for non-empty values in company, note, or website
      if (key === "company" || key === "note" || key === "website") {
        if (typeof obj[key] === "string" && obj[key].trim() !== "") {
          nonEmptyField = true;
        }
      }
      if (key === "contactFirstName" || key === "contactLastName") {
        if (typeof obj[key] === "string" && obj[key].trim() !== "") {
          nonEmptyField = true;
        }
      }
    }

    // If name is not empty or there's a non-empty field, return true
    return nameNotEmpty || nonEmptyField;
  };

  return hasNonEmptyField(contact);
};

export function isJSON(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const androidOdienceId = "com.summit.odience.client";
const iOsOdienceId = "id1512281957";
export const androidDesktopUrl = `https://play.google.com/store/apps/details?id=${androidOdienceId}&pli=1`;
export const iOSDesktopUrl = `https://apps.apple.com/ca/app/odience/${iOsOdienceId}`;
export const metaUrl = "https://www.meta.com/experiences/5674672632601679/";
export const androidUrl = `market://details?id=${androidOdienceId}`;
export const iosUrl = `itms-apps://itunes.apple.com/app/${iOsOdienceId}`;

export function openDownloadAppWebPage() {
  const userAgent = navigator.userAgent;

  const link = document.createElement("a");
  link.target = "_blank";

  if (/android/i.test(userAgent)) {
    link.href = androidUrl;
  } else {
    link.href = iosUrl;
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function insertOdd(arr: any[], elem: ((idx: number) => any) | object) {
  const isFunction = typeof elem === "function";
  for (let i = 1; i < arr.length; i += 2) {
    arr.splice(i, 0, isFunction ? elem(i) : elem);
  }
  return arr;
}
